import styled from 'styled-components';

const BannerStyled = styled.div`
  background-color: #EADED7;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  
`;
BannerStyled.Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin: auto;
  
  img{
  max-height: 400px;
  margin-bottom: -3px;
  }
  
   @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`
BannerStyled.Left = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
BannerStyled.Text = styled.span`
  font-weight: 700;
  font-size: 40px;
  
  p{
    font-size: 14px;
    color: #565656;
    font-weight: 500;
    line-height: 0.8;
    letter-spacing: 2.8px;
    text-transform: uppercase;
  }
`

export default BannerStyled;
