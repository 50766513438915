import React from 'react';
import HeaderInfo from "./HeaderInfo";
import Menu from "./Menu";
import HeaderActions from "./HeaderActions";
import Logo from '../images/logo-1.png'
import HeaderStyled from "../styled/HeaderStyled";
import HeaderFront from "./HeaderFront";


function Header() {
  return (
    <>
      <HeaderInfo/>
      <HeaderStyled>
        <div className="logo-mobile">
          <img src={Logo}/>
        </div>
        <div className='nav-mobile'>
          <Menu/>
        </div>
        <div className='icons-mobile'>
          <HeaderActions/>
        </div>

      </HeaderStyled>
      <HeaderFront/>

    </>
  )
}

export default Header;