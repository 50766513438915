import styled from 'styled-components';

const MenuBurger = styled.button`
  position: relative;
  outline: none;
  color: black;
  border: none;
  font-size: 24px;
  background-color: white;
  
  
 
  &:hover{
    color: #ff5353;
  }
  
  
  @media (min-width: 768px) {
   display: none;
  }`
MenuBurger.Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    

    
   .show{
    display:block;
  }
  .hide{
    display:none;
  }
`

MenuBurger.List = styled.ul`
    list-style-type: none;
    background-color: white;
    padding: 20px;
    line-height: 30px;
    
    
    
  
  li{
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
  }
    a {
      text-decoration: none;
      color: #888;
    }
      a:hover {
        text-decoration: none;
        color: #ff5353;
      }
`;
MenuBurger.Icon = styled.button`
  position: relative;
  
  @media (min-width: 768px) {
   display: none;
  }

`
export default MenuBurger;
