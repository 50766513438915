import React from 'react';
import HeaderFrontStyled from "../styled/HeaderFrontStyled";
import Front from '../images/ban-1.png'
import Button from "../styled/Button";
import {ImageRespons} from "../styled/ImageRespons";
import {HashLinkButton} from "../styled/NashLinkStyle";


function HeaderFront(){
  return(
    <>
      <HeaderFrontStyled>
        <HeaderFrontStyled.Wrap>

          <HeaderFrontStyled.Left>
            <HeaderFrontStyled.Text>
              <p>ultimate collection</p>
              Complete Women<br/> Fashion Here
            </HeaderFrontStyled.Text>
            <HashLinkButton smooth to={'/#most-popular'}><Button className='btn'>shop now</Button></HashLinkButton>
          </HeaderFrontStyled.Left>


          <div>
            <ImageRespons src={Front}/>
          </div>
        </HeaderFrontStyled.Wrap>
      </HeaderFrontStyled>
      </>
  )
}
export default HeaderFront;