import {makeAutoObservable} from "mobx";


class Data {
  items = 0;
  likes = 0;
  constructor() {
    makeAutoObservable(this)
  }
  additems() {
    this.items += 1;
  }
  addlikes() {
    this.likes += 1;
  }
  // AddLikes (){
  //   this.likes + 1
  // }
}
const data = new Data;
export default data;