import styled from 'styled-components';


const FooterStyled = styled.div`
  background-color: #EADED7;
  margin-bottom: -20px;
  
  hr{
   border: 1px solid #888;
   width: 60%;
   margin-top: 40px;
  }
  p{
    text-align: center;
    padding: 20px;
  }
  
`;
FooterStyled.Container = styled(FooterStyled)`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  
   @media (max-width: 768px) {
   flex-wrap: wrap;
  }
`;
FooterStyled.Column = styled.div`
  display: flex;
  flex-direction: column;
`;
FooterStyled.Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 0 15px;
`;
FooterStyled.List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
FooterStyled.SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
`
export default FooterStyled;
