import './App.css';
import ContainerStyled from "./styled/ContainerStyled";
import Header from "./components/Header";
import React from "react";
import Banner from "./components/Banner";
import FeatureProducts from "./components/FeatureProducts";
import MostPopular from "./components/MostPopular";
import TrendingProducts from "./components/TrendingProducts";
import Info from "./components/Info";
import Journal from "./components/Journal";
import Footer from "./components/Footer";
import ContentStyled from "./styled/ContentStyled";
import data from "./data/Data";
import {observable} from "mobx";
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router";
import LandingPage from "./LandingPage";

function App() {

  return (
    <>
      <Header/>
      <Routes>
        <Route path='/' index element={<LandingPage/>}/>

      </Routes>
      <Footer/>
    </>
  );
}

export default observer(App);
