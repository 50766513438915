import React from 'react';
import FeatureProductsStyled from "../styled/FeatureProductsStyled";
import OneItemWidth from "../styled/OneItemWidth";
import OneItem from "./OneItem";
import Img1 from "../images/8.jpg";
import Img2 from "../images/9.jpg";
import Img3 from "../images/10.jpg";
import Img4 from "../images/11.jpg";
import Img5 from "../images/12.jpg";
import Img6 from "../images/13.jpg";
import Img7 from "../images/14.jpg";
import Img8 from "../images/15.jpg";


function TrendingProducts (){
  const [products, setProducts] = React.useState([
    {image: Img1, title: 'Blue Sleeveless Dress', price: '$35'},
    {image: Img2, title: 'Peach Blouse', price: '$45'},
    {image: Img3, title: 'Blue Jersey', price: '$55'},
    {image: Img4, title: 'Yellow Turtleneck', price: '$55'},
    {image: Img5, title: 'Red Women Dress', price: '$35'},
    {image: Img6, title: 'Heavenly Blouse', price: '$45'},
    {image: Img7, title: 'Pale Pink Dress', price: '$55'},
    {image: Img8, title: 'Gently Pink Shirt', price: '$55'},
  ])
  return(
    <>
      <FeatureProductsStyled>
        {products.map((el) => <OneItemWidth itemWidth='23%'><OneItem image={el.image} title={el.title} price={el.price}/></OneItemWidth>)}
      </FeatureProductsStyled>
      </>
  )
}
export default TrendingProducts;