import styled from 'styled-components';
import {devices as device} from "./devices";

const HeaderFrontStyled = styled.div`
  background-image: linear-gradient(to right, #B7664C, #EBB7B1, #EBB7B1, #B7664C);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  
`;
HeaderFrontStyled.Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin: auto;
  
  img{
    max-height: 400px;
    margin-bottom: -3px;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`
HeaderFrontStyled.Left = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  
   .btn{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
`;
HeaderFrontStyled.Text = styled.span`
  font-weight: 700;
  font-size: 50px;
  
  p{
    font-size: 14px;
    color: #565656;
    font-weight: 500;
    line-height: 0.8;
    letter-spacing: 2.8px;
    text-transform: uppercase;
  }
 
`

export default HeaderFrontStyled;
