import React from 'react';
import StarRatingStyled from "../styled/StarRatingStyled";


function StarRating(){
  const [rating, setRating] = React.useState(0);
  const [hover, setHover] = React.useState(0);
  return(
    <>
      <div>
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <StarRatingStyled
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on" : "off"}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </StarRatingStyled>
          );
        })}
      </div>
      </>
  )
}
export default StarRating;