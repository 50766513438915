import React from 'react';
import FeatureProductsStyled from "../styled/FeatureProductsStyled";
import styled from 'styled-components';
import Img1 from "../images/4.jpg";
import Img2 from "../images/5.jpg";
import Img3 from "../images/6.jpg";
import Img4 from "../images/7.jpg";
import OneItem from "./OneItem";
import OneItemStyled from "../styled/OneItemStyled";
import OneItemWidth from "../styled/OneItemWidth";



function MostPopular(props){
  const [products, setProducts] = React.useState([
    {image: Img1, title: 'Black Women Pouch', price: '$35'},
    {image: Img2, title: 'Pink Mini Backpack', price: '$45'},
    {image: Img3, title: 'Silver High Heels', price: '$55'},
    {image: Img4, title: 'Blue Frame Sunglasses', price: '$55'},
  ])
  return(
    <>
      <FeatureProductsStyled>
        {products.map((el) => (
          <OneItemWidth itemWidth='23%'><OneItem image={el.image} title={el.title} price={el.price}/></OneItemWidth>))}
      </FeatureProductsStyled>
      </>
  )
}
export default MostPopular;