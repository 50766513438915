import styled from 'styled-components';


const Button = styled.button`
  background-color: #ff5353;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 500;
  max-width: 150px;
  
  &:hover{
    background-color: #fff;
    color: #ff5353;
  }
`;
export default Button;
