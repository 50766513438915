import React from 'react';
import HeaderInfoStyled from "../styled/HeaderInfoStyle";


function HeaderInfo (){
  return(
    <>
      <HeaderInfoStyled>
        <HeaderInfoStyled.Content>

          <p>Phone: +01 256 25 235 email: info@woom.com</p>
          <p>gift card track order language</p>

        </HeaderInfoStyled.Content>
      </HeaderInfoStyled>

      </>
  )
}
export default HeaderInfo;