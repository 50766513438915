import React from 'react';
import FooterStyled from "../styled/FooterStyled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpaceContainer from "../styled/SpaceContainer";


function Footer() {

  return (
    <>
      <FooterStyled>
        <SpaceContainer/>
        <FooterStyled.Container>
          <FooterStyled.Column>
            <FooterStyled.Title>
              About us
            </FooterStyled.Title>
            <FooterStyled.List>
              <li>Who we are</li>
              <li>Work with us</li>
              <li>Become a supplier</li>
              <li>Investor relations</li>
              <li>Our devices</li>
              <li>Affiliate program</li>
            </FooterStyled.List>
          </FooterStyled.Column>

          <FooterStyled.Column>
            <FooterStyled.Title>
              Useful links
            </FooterStyled.Title>
            <FooterStyled.List>
              <li>Who we are</li>
              <li>Work with us</li>
              <li>Become a supplier</li>
              <li>Investor relations</li>
              <li>Our devices</li>
              <li>Affiliate program</li>
            </FooterStyled.List>
          </FooterStyled.Column>

          <FooterStyled.Column>
            <FooterStyled.Title>
              Online shopping
            </FooterStyled.Title>
            <FooterStyled.List>
              <li>Who we are</li>
              <li>Work with us</li>
              <li>Become a supplier</li>
              <li>Investor relations</li>
              <li>Our devices</li>
              <li>Affiliate program</li>
            </FooterStyled.List>
          </FooterStyled.Column>


          <FooterStyled.Column>
            <FooterStyled.Title>
              experience app on mobile
            </FooterStyled.Title>
            <FooterStyled.List>
              <li>Who we are</li>
              <li>Work with us</li>
              <li>Become a supplier</li>
            </FooterStyled.List>
            <FooterStyled.Title>
              Online shopping
            </FooterStyled.Title>
            <FooterStyled.SocialMedia>

            </FooterStyled.SocialMedia>
          </FooterStyled.Column>


        </FooterStyled.Container>
        <hr/>
        <p>Copyright © 2021. All rights reserved.</p>
      </FooterStyled>
    </>
  )
}

export default Footer;