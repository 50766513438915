import styled from 'styled-components';


const JournalStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  
   @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
JournalStyled.Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  
   @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
  
`;
JournalStyled.Date = styled.p`
  font-size: 12px;
  line-height: 0;
  text-align: left;
  
  a {
    text-decoration: none;
    color: #888;
  }
  a:hover {
    text-decoration: none;
    color: #ff5353;
  }
`;
JournalStyled.Title = styled.p`
  font-size: 16px;
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
  
  a {
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
   a:hover {
    text-decoration: none;
    font-weight: bold;
    color: #ff5353;
  }
`;
JournalStyled.Tags = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -30px;
  align-items: center;
  justify-content: space-between;
  
  a {
    text-decoration: none;
    color: #999;
  }
  a:hover {
    text-decoration: none;
    color: #ff5353;
  }
  
  span{
    padding: 0 5px;
  }
`;



export default JournalStyled;
