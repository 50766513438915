import styled from 'styled-components';


const FeatureProductsStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
  
    @media (max-width: 800px) {
    flex-direction: row;
  }
  
    @media (max-width: 500px) {
    flex-direction: column;
  }

`;

export default FeatureProductsStyled;