import styled from 'styled-components';


const HeaderInfoStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fef7f6;
`
HeaderInfoStyled.Content = styled(HeaderInfoStyled )`
  width: 80%;
  margin: auto;
  
  p{
  font-size: 12px;
  text-transform: uppercase;
  }
`
export default HeaderInfoStyled;