import styled from 'styled-components'
import {NavHashLink} from "react-router-hash-link";

const HashLinkStyle = styled(NavHashLink)`
text-decoration: none;
color: #888;
    
      &:hover {
        text-decoration: none;
        color: #ff5353;
      }
`
export default HashLinkStyle;

export const HashLinkTop = styled(NavHashLink)`
text-decoration: none;
`;
export const HashLinkButton = styled(NavHashLink)`
text-decoration: none;
`;
