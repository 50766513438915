import styled from 'styled-components';


const OneItemWidth = styled.div`
  width: ${({itemWidth}) => itemWidth || '30%'};
  
  @media (max-width: 800px) {
    width: 45%;
  }
  
  @media (max-width: 500px) {
    width: 100%;
  }
`
export default OneItemWidth;