import React from 'react';
import OneItem from "./OneItem";
import FeatureProductsStyled from "../styled/FeatureProductsStyled";
import Img1 from "../images/1.jpg";
import Img2 from "../images/2.jpg";
import Img3 from "../images/3.jpg";
import OneItemWidth from "../styled/OneItemWidth";


function FeatureProducts(props) {
  const [products, setProducts] = React.useState([
    {image: Img1, title: 'Blue Dress', price: '$35'},
    {image: Img2, title: 'Turquoise Dress', price: '$45'},
    {image: Img3, title: 'White Suit', price: '$55'},
  ])
  return (
    <>
      <FeatureProductsStyled>
        {products.map((el) => <OneItemWidth itemWidth='30%'><OneItem image={el.image} title={el.title} price={el.price}/></OneItemWidth>)}
      </FeatureProductsStyled>
    </>
  )
}

export default FeatureProducts;