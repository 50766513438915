import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons/faShoppingBasket";
import {faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import HeaderStyled from "../styled/HeaderStyled";
import data from "../data/Data";
import {observer} from "mobx-react-lite";

function HeaderActions (){
  return(
    <>
      <HeaderStyled.Icons>
        <li>
          <a href='#'><FontAwesomeIcon icon={faSearch} /></a>
        </li>
        <li>
          <a href='#'><FontAwesomeIcon icon={faShoppingBasket} /><span>{data.items === 0 ? null : data.items}</span></a>
        </li>
        <li>
          <a href='#'><FontAwesomeIcon icon={faHeart} /><span>{data.likes === 0 ? null : data.likes}</span></a>
        </li>
      </HeaderStyled.Icons>
      </>
  )
}
export default observer(HeaderActions);