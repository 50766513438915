import styled from 'styled-components';

const OneItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  
  img {
    width: 100%;
  }
   a.title{
     font-size: 20px;
     text-decoration: none;
     color: black;
   }
   a.title:hover{
     font-size: 20px;
     text-decoration: none;
     color: #ff5353;
   }
   
   p.price{
     font-size: 20px;
     font-weight: bold;
     line-height: 0;
   }
   span.raiting{
     justify-content:center;
     line-height: 0px;
   }
`;


OneItemStyled.Content = styled(OneItemStyled)`
  text-align: center;
  width: 100%;
`

OneItemStyled.ContainerCircle = styled.div`
   position: absolute;
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 100%;
   bottom: 200px;
`;
OneItemStyled.Circle = styled.div`
   position: relative;
   line-height: 50px;
   text-align: center;
   margin: 5px;
   height: 50px;
   width: 50px;
    left: auto;
   right: auto;
   background-color: white;
   border-radius: 100%;
   top: 100px;
   opacity: 0;
   transition: all ${({transitionTime}) => transitionTime || '0.5s'} ease-in-out;

  ${OneItemStyled}:hover & {
    opacity: 1;
    top: 0;
  }
   
 
 &:hover{
  background-color: #ff5353;
  color: white;
 }

`
export default OneItemStyled;