import styled from 'styled-components';
import {devices as device} from "./devices";


const HeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  align-items: center;
  padding: 15px 0;
  .logo-mobile{
      order: 1;
      }
      .nav-mobile{
      order: 2;
      }
      .icons-mobile{
      order: 3;
      }

  
    @media (max-width: 768px) {
      .logo-mobile{
      order: 1;
      }
      .nav-mobile{
      order: 3;
      }
      .icons-mobile{
      order: 2;
      }

  }
`;
HeaderStyled.Icons = styled.ul`
 display: flex;
 flex-direction: row;
 list-style-type: none;
 position: relative;
 
   li{
    padding: 0 5px;
    position: relative;
   }
     a {
      text-decoration: none;
      color: #999;
      }
        a:hover {
          text-decoration: none;
          color: #ff5353;
        }
        
        span{
          right: -10px;
  position: absolute;
  top: -15px;
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  color: #ff5353;
 border: none;
  font-size: 12px;
  font-weight: 900;
        }
  
`

export default HeaderStyled;
