import React from 'react';
import JournalStyled from "../styled/JournalStyled";
import Img1 from "../images/blog1.jpg";
import Img2 from "../images/blog2.jpg";
import Img3 from "../images/blog3.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons/faBox";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";


function Journal() {
  const [posts, setPosts] = React.useState([
    {image: Img1, date: '07 October, 2021', title: 'Were divided land his creature which have evening subdue'},
    {image: Img2, date: '07 November, 2021', title: 'Were divided land his creature which have evening subdue'},
    {image: Img3, date: '07 December, 2021', title: 'Were divided land his creature which have evening subdue'}
  ])
  return (
    <>
      <JournalStyled>
        {posts.map((el) => (
          <JournalStyled.Card>
            <img src={el.image}/>
            <JournalStyled.Date><a href='#'>{el.date}</a></JournalStyled.Date>
            <JournalStyled.Title><a href='#'> {el.title}</a></JournalStyled.Title>
            <hr />
            <JournalStyled.Tags>
              <a href='#'><span><FontAwesomeIcon icon={faBox}/></span><span>Women Fashion</span></a>
              <a href='#'><span><FontAwesomeIcon icon={faComments}/></span><span>2 Comments</span></a>
              </JournalStyled.Tags>
          </JournalStyled.Card>
        ))}
      </JournalStyled>
    </>
  )
}

export default Journal;