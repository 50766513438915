import React from 'react';
import Button from "../styled/Button";
import Front from "../images/ban-2.png";
import BannerStyled from "../styled/BannerStyled";
import {ImageRespons} from "../styled/ImageRespons";
import {TextRed} from "../styled/TextRed";
import {HashLinkButton} from "../styled/NashLinkStyle";


function Banner(){
  return(
    <>
      <BannerStyled>
        <BannerStyled.Wrap>

          <BannerStyled.Left>
            <BannerStyled.Text>
              50% off
              <p>all women’s collection</p>
              <HashLinkButton smooth to={'/#trending-product'}><Button>shop now</Button></HashLinkButton>
            </BannerStyled.Text>
          </BannerStyled.Left>


          <div>
            <ImageRespons src={Front}/>
          </div>
        </BannerStyled.Wrap>
      </BannerStyled>
      </>
  )
}
export default Banner;