import React from 'react';
import OneItemStyled from "../styled/OneItemStyled";
import Img1 from '../images/1.jpg'
import Img2 from '../images/2.jpg'
import Img3 from '../images/3.jpg'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons/faShoppingBasket";
import {faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import StarRating from "./StarRating";
import data from "../data/Data";
import {observable, observe} from "mobx";
import {observer} from "mobx-react-lite";

function OneItem(props) {

  const onClickBuy = () => {
    data.additems()
  }
  const onClickHeart = () => {
    data.addlikes()
  }


  return (
    <>
      <OneItemStyled>
        <img src={props.image}/>
        <OneItemStyled.Content>
          <a className='title'>{props.title}</a>
          <span><StarRating/></span>
          <p className='price'>{props.price}</p>
        </OneItemStyled.Content>




          <OneItemStyled.ContainerCircle >
            <OneItemStyled.Circle transitionTime='0.2s'>
              <FontAwesomeIcon icon={faEye}/>
            </OneItemStyled.Circle>
            <OneItemStyled.Circle transitionTime='0.3s' onClick={onClickHeart}>
              <FontAwesomeIcon icon={faHeart} />
            </OneItemStyled.Circle>
            <OneItemStyled.Circle transitionTime='0.4s' onClick={onClickBuy}>
              <FontAwesomeIcon icon={faShoppingBasket} />
            </OneItemStyled.Circle>
          </OneItemStyled.ContainerCircle>


      </OneItemStyled>
    </>
  )
}

export default observer(OneItem);