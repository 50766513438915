import React from 'react';
import MenuStyled from "../styled/MenuStyled";
import MenuBurger from "../styled/MenuBurger";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import HashLinkStyle from "../styled/NashLinkStyle";

function Menu() {
  const [isActive, setActive] = React.useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };
  // const scrollWidthOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -10;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  // }

  return (
    <>
      <MenuStyled>
        <li><HashLinkStyle smooth to={'/'} >Home</HashLinkStyle></li>
        <li><HashLinkStyle smooth to={'/#featured-product'} >Featured products</HashLinkStyle></li>
        <li><HashLinkStyle smooth to={'/#most-popular'} >most popular</HashLinkStyle></li>
        <li><HashLinkStyle smooth to={'/#info'} >info</HashLinkStyle></li>
        <li><HashLinkStyle smooth to={'/#trending-product'} >trending products</HashLinkStyle></li>
        <li><HashLinkStyle smooth to={'/#blog'} >Blog</HashLinkStyle></li>
        {/*<li><a href='#'>Contact</a></li>*/}
      </MenuStyled>




      <MenuBurger onClick={handleToggle}><FontAwesomeIcon icon={faBars}/></MenuBurger>
      <MenuBurger.Content >
        <MenuBurger.List className={isActive ? "hide" : "show"}>
          <li><HashLinkStyle smooth to={'/'}>Home</HashLinkStyle></li>
          <li><HashLinkStyle smooth to={'/#featured-product'} >Featured products</HashLinkStyle></li>
          <li><HashLinkStyle smooth to={'/#most-popular'}>most popular</HashLinkStyle></li>
          <li><HashLinkStyle smooth to={'/#info'}>info</HashLinkStyle></li>
          <li><HashLinkStyle smooth to={'/#trending-product'}>trending products</HashLinkStyle></li>
          <li><HashLinkStyle smooth to={'/#blog'}>Blog</HashLinkStyle></li>
        </MenuBurger.List>
      </MenuBurger.Content>
    </>
  )
}

export default Menu;