import styled from 'styled-components';


const MenuStyled = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  
  @media (max-width: 768px) {
   display: none;
  }
  
  li{
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
  }
    a {
      text-decoration: none;
      color: #888;
    }
      a:hover {
        text-decoration: none;
        color: #ff5353;
      }
`;
export default MenuStyled;
