import React from 'react';
import ContainerStyled from "./styled/ContainerStyled";
import ContentStyled from "./styled/ContentStyled";
import FeatureProducts from "./components/FeatureProducts";
import Banner from "./components/Banner";
import MostPopular from "./components/MostPopular";
import Info from "./components/Info";
import TrendingProducts from "./components/TrendingProducts";
import Journal from "./components/Journal";
import {HashLink} from "react-router-hash-link";
import SpaceContainer from "./styled/SpaceContainer";
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons/faShoppingBasket";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {HashLinkTop} from "./styled/NashLinkStyle";
import ToTop from "./styled/ToTop";
import {TextRed} from "./styled/TextRed";
import Zoom from "react-reveal";


const LandingPage = () => {
  return(
    <>
      <Zoom>
      <ContainerStyled>
        <SpaceContainer id='featured-product'/>
        <h1>Featured product</h1>
      </ContainerStyled>
      <ContentStyled>
        <FeatureProducts/>
      </ContentStyled>
      </Zoom>

      <Zoom><SpaceContainer/>
        <Banner/></Zoom>

      <Zoom>
      <SpaceContainer id='most-popular'/>
      <ContainerStyled>
        <h1>Most popular</h1>
      </ContainerStyled>
      <ContentStyled>
        <MostPopular/>
      </ContentStyled>
      </Zoom>
      <Zoom>
      <SpaceContainer id='info'/>
      <ContentStyled>
        <Info/>
      </ContentStyled>
      </Zoom>

      <SpaceContainer id='trending-product'/>
      <ContainerStyled>
        <h1>Trending products</h1>
      </ContainerStyled>
      <ContentStyled>
        <TrendingProducts/>
      </ContentStyled>
     
      <Zoom>
      <SpaceContainer id='blog'/>
      <ContainerStyled>
        <h1>woom journal</h1>
      </ContainerStyled>
      <ContentStyled>
        <Journal/>
        <SpaceContainer/>
        <ToTop><HashLinkTop smooth to="#top"><TextRed><FontAwesomeIcon icon={faArrowUp} size="lg" beat /></TextRed></HashLinkTop></ToTop>
      </ContentStyled>
      </Zoom>
      </>
  )
}
export default LandingPage;