import React from 'react';
import InfoStyled from "../styled/InfoStyled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandHoldingUsd} from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import {faTruck} from "@fortawesome/free-solid-svg-icons/faTruck";
import {faHeadset} from "@fortawesome/free-solid-svg-icons/faHeadset";
import {faUserShield} from "@fortawesome/free-solid-svg-icons/faUserShield";

function Info(){
  const [info, setInfo] = React.useState([
    {icon: faHandHoldingUsd, content: 'Money back', description: 'Great fill signs he evening'},
    {icon: faTruck, content: 'Free Delivery', description: 'Great fill signs he evening'},
    {icon: faHeadset, content: 'Alway support', description: 'Great fill signs he evening'},
    {icon: faUserShield, content: 'Secure payment', description: 'Great fill signs he evening'},
  ])
  return(
    <>
      <InfoStyled>
        {info.map((el) => (
          <InfoStyled.Card>
            <FontAwesomeIcon icon={el.icon} className='icon-style'/>
            <InfoStyled.Title>{el.content}</InfoStyled.Title>
            <InfoStyled.Description>{el.description}</InfoStyled.Description>
          </InfoStyled.Card>
        ))}
      </InfoStyled>

      </>
  )
}
export default Info;