import styled from 'styled-components';

const StarRatingStyled = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

.on {
  color: #000;
}
.off {
  color: #ccc;
}

`;

export default StarRatingStyled;
