import styled from 'styled-components';


const ContainerStyled = styled.div`
display: flex;
flex-direction: column;
max-width: 80%;
margin: auto;
`;

export default ContainerStyled;
